import Vue from 'vue'

const moment = Vue.prototype.$moment

export default {
  getLocaleDateFromUtcMomentObject (date, format, timezone) {
    return moment.utc(date, format).tz(timezone)
  },

  createUtcMomentObjectFromLocaleDate (date, format, timezone) {
    const now = moment.tz(timezone)
    const startHour = moment(date, format)
    now.set({ hour: startHour.hour(), minutes: startHour.minutes() })
    return moment.utc(now, 'hh:mm A')
  }
}
