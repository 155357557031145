<template>
  <div class="mb-3">
    <label for="" class="label">{{ label }}</label>
    <v-select :value="value" v-bind="$attrs" class="custom-select" height="50px" hide-details plain outlined
              @input="$emit('input', $event)"
    >
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',
  inheritAttrs: false,
  props: {
    label: String,
    value: [String, Object, Number]
  }
}
</script>

<style scoped lang="scss">

.label {
  font-size: 16px;
  line-height: 18px;
  color: #C4C4C4;
  letter-spacing: -0.06em;
}

.custom-select {
  border-radius: 10px !important;
}

.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 36px;
}

.v-text-field ::v-deep .v-select__selection--comma {
  color: #243674;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;
}

.v-text-field ::v-deep .v-select__selections  {
  color: #243674;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;
}

</style>
